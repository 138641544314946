.OrderItemTable {

  width: 100%;
  font-size: 13px;

  td {
    border: 1px solid #ececec;
    padding: 4px;
    text-align: left;

    &.ItemPrice {
      text-align: right !important;
    }
  }

  th {
    text-align: left;
  }

}
