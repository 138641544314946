@import "Fonts";

body, html {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.App {
  height: 100%;
}

#root {
  height: 100%
}

.content {
  padding-left: 60px;
  background-color: #f0f2f5; /*#eff1f7;*/
  transition: padding-left .2s linear;
  -webkit-transition: padding-left .2s linear;
  position: relative;
  overflow-y: auto;
  margin: 0;
  height: 100%;
}

.content.expanded {
  padding-left: 300px;

}

.page {
  padding: 12pt;
  margin-top: 64px;
}

.list-page-actions, .page-actions {
  text-align: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

.page-actions > button {
  margin-left: 5px;
}

.page-loading {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.data-table {
  margin-top: 10px;
}

.data-table td, .data-table th {
  border-right: 1px solid #e0e0e0;
}

.data-table td:last-child, .data-table th:last-child {
  border-right: none;
}

.data-table .MuiTableRow-head {
  border-top: 1px solid #e0e0e0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.login-panel {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: #1f2633;

  /*background-image: url('../assets/images/earth-bg.jpg');*/
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}


.order-list-item-view:hover {
  background-color: #f8f8f6;
  cursor: pointer;
}


@import "OrderTable";

