
a:hover, a:focus {
    text-decoration: none;
}


nav ul, nav li {
    outline: 0;
    margin: 0;
    padding: 0;
}

.side-menu:hover, .side-menu.expanded {
    width: 300px;
    overflow: visible;
}

.side-menu {
    background: #1c1e2a;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 60px;
    overflow-x: hidden;
    -webkit-transition: width .2s linear;
    transition: width .2s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 9999;
    display: flex;
    flex-direction: column;

    -webkit-box-shadow: 1px 0 1px 1px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 1px 0 1px 1px rgba(0, 0, 0, 0.35);
    box-shadow: 1px 0 1px 1px rgba(0, 0, 0, 0.35);
}


.side-menu > ul.menu {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.boldIconWrap {
    position: relative;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 70px;
    height: 36px;
}

.boldMenu {
    display: table;
    width: 300px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #303c54;
    border-bottom: 1px solid #252525;
}

.boldIcon {
    width: 24px;
    height: 24px;
}

.boldIconLabel {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 190px;
    color: white;
    font-weight: bold;
}

.boldPinMenu {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.side-menu .bold-logo {
    font-weight: bold;
    color: white !important;
}


.sideMenu {
    position: fixed;
    top: 55px;
    left: 60px;
    bottom: 0;
    overflow: hidden;
    background-color: #303c54;
}

.sideMenuSplit {
    bottom: 0;
    left: 100%;
    position: absolute;
    top: 0;
    transform: translateZ(0px);
    width: 24px;
    z-index: 3;
}

.menu .MuiButtonBase-root {
    width: 300px;
}

.side-menu .MuiListItemIcon-root, .side-menu .MuiListItemText-primary {
    color: white;
    font-weight: bold;
}


.side-menu .MuiListItem-button:hover {
    color: #fff;
    background-color: #303c54;
}

.side-menu .MuiListItem-button.active .MuiListItemIcon-root, .side-menu .MuiListItem-button.active .MuiListItemText-primary {
    color: #ffef00;
}

.submenuItem {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    font-weight: normal !important;
}

.side-menu .MuiListItemIcon-root .submenuItem, .side-menu .submenuItem .MuiListItemText-primary {
    color: white;
    font-weight: normal;

}


.side-menu:hover .MuiListItemIcon-root .submenuItem, .side-menu.expanded .submenuItem .MuiListItemText-primary {

}
